html, 
body,
#root {
  height: 100%;
}

.noscroll {
  overflow: hidden;
  height: 100%;
}

.sidebar {
  margin-top: 50px;
  z-index: 1500;
  position: fixed;
  left: -100%;
  height: calc(100% - 50px);
  width: 100%;
  transition: left 0.3s ease-in-out;
  background-color: gray;
  /* background-color: white; */
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  transition: left 0.3s ease-in-out;
  /* background-color: lightgray; */
}

.sidebar.open {
  left: 0;
}

.content.open {
  left: 200px;
}

.progress-bar {
  height: 4px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(5, 114, 206);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}

/* Styling the menu button */
.menu-btn {
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
  vertical-align: middle;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

/* Styling the hamburger lines */
.menu-btn .btn-line {
  width: 26px;
  height: 3px;
  margin: 3px 5px 3px 5px;
  background: white;
  transition: all 0.3s ease-out;
}

/* Styling the three lines to make it an X */
.menu-btn.close .btn-line:nth-child(1) {
  transform: rotate(45deg) translate(6px, 6px);
}

.menu-btn.close .btn-line:nth-child(2) {
  opacity: 0;
}

.menu-btn.close .btn-line:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -6px);
}
